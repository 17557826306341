import React, { useState } from 'react'
import { SideNavBarStyled, LinkStyled } from './Navbar.style'
import {BiLeftArrow} from "react-icons/bi"

function Navbar() {
    const [isMenuOpen, setIsMenuOpen] = useState(null);
    function handleClick(){
        setIsMenuOpen(prevState => prevState = !prevState )
    }
  return (
    <SideNavBarStyled open={isMenuOpen}>
            <ul>
              <img id='navbar-icon' src="icons/favicon.ico" alt='LR icon'/>
                <LinkStyled activeClass="active"  spy={true} to="welcome"  smooth={true} offset={0} duration={500} > Welcome </LinkStyled>
                <LinkStyled activeClass="active"  spy={true} to="about"  smooth={true} offset={0} duration={500} > About </LinkStyled>
                <LinkStyled activeClass="active"  spy={true} to="skills"  smooth={true} offset={0} duration={500} > Skills </LinkStyled>
                <LinkStyled activeClass="active"  spy={true} to="projects"  smooth={true} offset={0} duration={500} > Projects </LinkStyled>
                <LinkStyled activeClass="active"  spy={true} to="contacts"  smooth={true} offset={0} duration={500} > Contacts </LinkStyled>
                <a href='https://github.com/reikal1337' target="_blank" rel="noreferrer" >Github</a>
            </ul>
            <div className='vl'></div>
            <BiLeftArrow onClick={handleClick} className='icon-arrow'/>
            
    </SideNavBarStyled>

  )
}

export default Navbar