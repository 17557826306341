import styled, { keyframes } from "styled-components";

const SlideAnimation = keyframes`
0%{
    opacity: 0;
    display: flex;
}
100%{
    opacity: 1;
}

`


export const StyledSlider = styled.div`
    .slide{
        opacity: 0;
        display: none;
    }
    .slide-animation{
        animation: ${SlideAnimation} 0.5s ease-in-out forwards;
    }

    .slider-controls{
        
        display: flex;
        flex-direction: row;

        justify-content: center;
        align-items: center;
        padding-bottom: 5px;
        border-bottom: inset 4px black;
        .slider-button{
            font-size: 30px;
            transition: color 0.3s ease;
            cursor: pointer;
            &:hover,&:active{
                color: black;
            }
        } 
        .slider-counter{
                font-size: 20px;
                font-weight: 600;
                min-width: 40px;

            }
    }
    

`