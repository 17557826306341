import { useGLTF } from "@react-three/drei";

export function PythonLogo(props) {
  const { nodes, materials } = useGLTF("models/pythonLogo.gltf");
  return (
    <group ref={props.refs} position={[0,0,0]} rotation-y={props.startingPosition}>
        <group {...props} dispose={null} position={props.position} scale={props.scale}>
        <mesh
            geometry={nodes.Curve195.geometry}
            material={materials["SVGMat.203"]}
        />
        <mesh
            geometry={nodes.Curve195_1.geometry}
            material={materials["SVGMat.202"]}
        />
        </group>
    </group>
  );
}

useGLTF.preload("models/pythonLogo.gltf");