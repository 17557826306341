import { StyledFooter } from "./Footer.styles"
import { FaLinkedin } from 'react-icons/fa'
import GmailIcon from "./contacts/GmailIcon"
import { AiFillGithub } from 'react-icons/ai';

function Footer() {
  return (
    <StyledFooter>
        <p>Copyright &copy; 2022-{(new Date().getFullYear())} All Rights Reserved by
        <a href="https://www.lukasreika.com" target="_blank" rel="noreferrer"> Reika Developments</a></p>
        <div className="footer-icons">
          <a href="https://www.linkedin.com/in/lukas-reika" target="_blank" rel="noreferrer"><FaLinkedin id="footer-linkedin" /></a>
          <a href="mailto:reikal951@gmail.com" target="_blank" rel="noreferrer"><GmailIcon isHovered={true} id="footer-gmail" /></a>
          <a href="https://github.com/reikal1337" target="_blank" rel="noreferrer"><AiFillGithub id="footer-github" /></a>
        </div>
        
    </StyledFooter>
  )
}

export default Footer