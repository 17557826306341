import styled, { keyframes } from "styled-components";
import { StyledSection } from "../styles/StyledSection";
import { ContentContainer } from "../styles/ContentContainer";
import { deviceSize } from "../styles/deviceSize";


const animationContainer = keyframes`
    0%{
        opacity: 0;
        transform: translateX(100%);
    }
    80%{
        transform: translateX(-5%);
    }
    100%{
        opacity: 1;
        transform: translateX(0%);
    }

`


export const SkillsSection = styled(StyledSection)`
    scroll-snap-align: center;
    overflow: hidden;
    @media ${deviceSize.customS} {
        height: 100%;
        
  }

`

export const StyledContainer = styled(ContentContainer)`
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 760px;
    margin-inline: 15px;
    opacity: 0;
    transform: translateX(100%);
    animation: 
    
    ${props => props.isAnimation ? animationContainer : "none"} 2s ease-out 0s 1 forwards;
    /* max-height: 500px; */
    
    h2{
        margin: 10px;
        text-align: center;
    }
    h3{
        margin: 10px;
        font-size: 15px;
    }

    .cards-container{
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        color: black;
    }


    .card-test{
        color: yellow;
        animation-delay: 10s;
    }
    .other-text{
        margin: 0 10px 10px 10px ;
        border: 3px inset black;
        box-shadow: inset 1px 1px 10px 1px black;
        background-color: rgb(50,50,50);
        padding: 15px;
        p{
            margin-bottom: 5px;
            /* margin: 10px; */
        }

    }
`
