import styled, { keyframes } from "styled-components";
import { StyledCard } from "../styles/StyledCard.styles";
import { deviceSize } from "../styles/deviceSize";

const animationCardPopUp = keyframes`
    0%{
        opacity: 0;
        transform: scale(0.2);
    }
    100%{
        opacity: 1;
        transform: scale(1);
    }

`

const animationCard = keyframes`
    0%{
        opacity: 0;
        transform: scale(0.2) rotateX(0deg) translatey(-100%) rotateX(0deg);
        box-shadow: 20px 15px 20px 0px black;
    }
    90%{
        box-shadow: 20px 15px 20px 0px black;
    }
    100%{
        opacity: 1;
        transform: scale(1) rotateX(360deg) translateX(0) rotateX(-360deg);
        box-shadow: 5px 10px 20px 10px black;
    }

`

export const StyledProjectCard = styled(StyledCard)`
    width: 230px;
    margin: 10px;
    opacity: 0;
    animation: 
    ${props => props.isAnimation ? animationCard : "none"} 3s ease-out 1.5s 1 forwards;
    &:nth-child(1){
        animation-delay: 1.7s;
    }
    &:nth-child(2){
        animation-delay: 1.9s;
    }
    &:nth-child(3){
        animation-delay: 2.1s;
    }
    &:nth-child(4){
        animation-delay: 2.3s;
    }

    .content-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
    }

    img{
        width: 200px;
        height: 200px;
        margin: 10px;
        border-radius: 10px 10px 0 0;
    }

    h4{
        font-size: 13px;
        text-align: center;
    }
    p{
        min-height: 56px;
        font-size: 10px;
        margin: 10px 5px 10px 10px;
    }

    .loading-dis{
        margin: 0px;
        padding: 0px;
        color: silver;
        font-size: 12px;
        font-weight: normal;
        margin-bottom: 5px;
    }
    
    .show-more{
        color: white;
        text-decoration: underline;
        &:hover{
            color: grey;
        }
        &:active{
            color: red;
        }

    }
    .icons-container{
        margin-top: auto;
        text-align: center;
       
    }

    .icon{
        font-size: 25px;
        color: black;
        margin: 5px;
        transition: 0.3s linear;
        &:hover{
            transform: scale(1.2);
            color: whitesmoke;
        }
        &:active{
            color: red;
        }
    }



`


export const StyledProjectCardPopUp = styled(StyledProjectCard)`
    width: 100%;
    max-width: 680px;
    height: auto;
    z-index: 2;
    position: absolute;
    opacity: 0;
    animation: ${animationCardPopUp} 1s ease-out 0s 1 forwards;

    h4{
        margin: 10px 5px 10px 5px;
        font-size: 20px;
    }
    p{
        font-size: 13px;
        margin: 0 15px 10px 15px;
        border: 3px inset black;
        box-shadow: inset 1px 1px 10px 1px black;
        background-color: rgb(50,50,50);
        padding: 7px;
    }
    span{
        text-align: center;
        margin-bottom: 10px;
        b{
            font-weight: 800;
        }
    }
    img{
        width: 640px;
        height: 480px;
        border-radius: 10px 10px 0 0;
    }

    .exit-button{
       position: absolute;
       right: 7px;
       top: 7px;
       color: #911E06;
       font-size: 30px;
       z-index: 10;
       margin: 5px;
       transition: 0.3s linear;
       &:hover{
        transform: scale(1.2);
        color: #DB2C07;
       }
    }
    .icon{
        font-size: 40px;
    }

    @media ${deviceSize.tablet} {
        max-width: 440px;
        span{
            font-size: 13px;
        }
        img{
            width: 400px;
            height: 300px;
        }
        
  }

  @media ${deviceSize.mobileL} {
        max-width: 330px;
        img{
            width: 300px;
            height: 225px;
        }
        
  }

  @media ${deviceSize.mobileS} {
        max-width: 300px;
        img{
            width: 250px;
            height: 187.50px;
        }
        
  }


        

`