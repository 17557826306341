import { useState } from "react"

const HoverAnimation = ({animationClass, children}) => {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <div className={isHovered ? animationClass : ""}
    onMouseEnter={() => setIsHovered(true)}
    onAnimationEnd={() => setIsHovered(false)}
    >
      {children}
    </div>
  )
}

export default HoverAnimation