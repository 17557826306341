import { useGLTF } from "@react-three/drei";

export function CssLogo(props) {
  const { nodes, materials } = useGLTF("models/cssLogo.gltf");
  return (
    <>
    <group ref={props.refs} position={[0,0,0]} rotation-y={props.startingPosition}>
      <group {...props} dispose={null} position={props.position} scale={props.scale} >
        <mesh
          geometry={nodes.path3001.geometry}
          material={materials["SVGMat.125"]}
        />
        <mesh
          geometry={nodes.path3001_1.geometry}
          material={materials["SVGMat.115"]}
        />
        <mesh
          geometry={nodes.path3001_2.geometry}
          material={materials["SVGMat.123"]}
        />
        <mesh
          geometry={nodes.path3001_3.geometry}
          material={materials["SVGMat.124"]}
        />
      </group>
    </group>
    </>
  );
}

useGLTF.preload("models/cssLogo.gltf");