import { useGLTF } from "@react-three/drei";

export function JsLogo(props) {
  const { nodes, materials } = useGLTF("models/jsLogo.gltf");
  return (
    <group ref={props.refs} position={[0,0,0]} rotation-y={props.startingPosition}>
        <group {...props} dispose={null} position={props.position} scale={props.scale}>
        <group scale={1.04}>
            <mesh
            geometry={nodes.Curve011_1.geometry}
            material={materials["SVGMat.012"]}
            />
            <mesh
            geometry={nodes.Curve011_2.geometry}
            material={materials["SVGMat.006"]}
            />
            <mesh
            geometry={nodes.Curve011_3.geometry}
            material={materials["SVGMat.011"]}
            />
        </group>
        </group>
    </group>
  );
}

useGLTF.preload("models/jsLogo.gltf");