import {StyledProgressBar, StyledProgress} from "./ProgressBar.styles"; 
import { nanoid } from "nanoid";

export default function ProgressBar(props) {
    const level1 =  
        <StyledProgress key={nanoid()} color="linear-gradient(90deg, rgba(1,255,0,1) 75%, rgba(255,175,0,1) 100%);" border="10px 0 0 10px;" />
    const level2 =  
        <StyledProgress key={nanoid()} color="linear-gradient(90deg, rgba(255,175,0,1) 75%, rgba(255,0,0,1) 100%);" border="0;"/>
    const level3 =  
        <StyledProgress key={nanoid()} color="linear-gradient(90deg, rgba(255,0,0,1) 75%, rgba(133,14,176,1) 100%);" border="0;"/>
    const level4 =  
        <StyledProgress key={nanoid()} color="#850eb0;" border="0 10px 10px 0;"/>
    const fillProgresion = (level) => {
        switch (level) {
            case "1":
                return level1
            case "2":
                return [level1, level2]
            case "3":
                return [level1, level2, level3]
            case "4":
                return [level1, level2, level3, level4]
        
            default:
                break;
        }
    }

    return(
    <StyledProgressBar>
        {fillProgresion(props.level)}
    </StyledProgressBar>
    )
}