import { useGLTF, useLoader } from "@react-three/drei";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

export function JavaLogo(props) {
    const { nodes, materials } = useGLTF("models/javaLogo.gltf");
  return (
<group ref={props.refs} position={[0,0,0]} rotation-y={props.startingPosition}>
        <group {...props} dispose={null} position={props.position} scale={props.scale}>
        <mesh
            geometry={nodes.Curve107.geometry}
            material={materials["SVGMat.074"]}
        />
        <mesh
            geometry={nodes.Curve107_1.geometry}
            material={materials["SVGMat.064"]}
        />
        <mesh
            geometry={nodes.Curve107_2.geometry}
            material={materials["SVGMat.076"]}
        />
        <mesh
            geometry={nodes.Curve107_3.geometry}
            material={materials["SVGMat.065"]}
        />
        <mesh
            geometry={nodes.Curve107_4.geometry}
            material={materials["SVGMat.077"]}
        />
        <mesh
            geometry={nodes.Curve107_5.geometry}
            material={materials["SVGMat.066"]}
        />
        <mesh
            geometry={nodes.Curve107_6.geometry}
            material={materials["SVGMat.067"]}
        />
        <mesh
            geometry={nodes.Curve107_7.geometry}
            material={materials["SVGMat.068"]}
        />
        <mesh
            geometry={nodes.Curve107_8.geometry}
            material={materials["SVGMat.069"]}
        />
        <mesh
            geometry={nodes.Curve107_9.geometry}
            material={materials["SVGMat.070"]}
        />
        <mesh
            geometry={nodes.Curve107_10.geometry}
            material={materials["SVGMat.071"]}
        />
        <mesh
            geometry={nodes.Curve107_11.geometry}
            material={materials["SVGMat.072"]}
        />
        <mesh
            geometry={nodes.Curve107_12.geometry}
            material={materials["SVGMat.073"]}
        />
        <mesh
            geometry={nodes.Curve107_13.geometry}
            material={materials["SVGMat.075"]}
        />
        <mesh
            geometry={nodes.Curve107_14.geometry}
            material={materials["SVGMat.078"]}
        />
        <mesh
            geometry={nodes.Curve107_15.geometry}
            material={materials["SVGMat.079"]}
        />
        </group>
    </group>
  );
}

useGLTF.preload("models/javaLogo.gltf");