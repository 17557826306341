import AnimatedTitle from "./AnimatedTitle"
import AnimatedParagraph from "./AnimatedParagraph"
import { WelcomeSection } from "./Welcome.styles"

export default function Welcome (props) {

    return(
        <WelcomeSection id={props.id}>
            <AnimatedTitle />
            <AnimatedParagraph />
        </WelcomeSection>
    )

}
