import scrabbleImg from "../../images/projects/scrabble.png"

import { workoutLog } from "../../images/projects/"
import { canaria } from "../../images/projects/"
import { saga } from "../../images/projects/"
import { nanoid } from "nanoid"

const projectObjects = [

    {
        id: nanoid(),
        name: "Saga(Next.js)",
        img: saga.saga1,
        images: [saga.saga2,saga.saga3,saga.saga4,saga.saga5,saga.saga6],
        loading: "Prod waiting for client",
        summary: "A website to view events, photos, videos, has admin auth. Created using next.js",
        description: "Built on the Next.js framework, this web platform showcases past and upcoming events, photos, and videos. Admins access via secure link/password authentication, enabling CRUD functionalities for events. Integration with Cloudinary streamlines photo album uploads, while YouTube video embedding is seamless. This dynamic site offers users a curated view of events and multimedia content. The project leverages Next.js' efficiency for interactive experiences, empowering admins to manage events effortlessly. Its robust authentication and multimedia capabilities ensure a user-friendly exploration of events and content, maintaining a seamless platform for event discovery.",
        github: undefined,
        preview: undefined,
        languages: "TypeScript/Html/Css",
        tags: "Next.js/Tailwind/MongoDB"
    },
    {
        id: nanoid(),
        name: "Workout Log(MERN)",
        img: workoutLog.img1,
        images: [workoutLog.img2,workoutLog.img3,workoutLog.img4,workoutLog.img5,workoutLog.img6,workoutLog.img7],
        loading: "15s-30s spin up",
        summary: "Website in wich you can create/save exercieses to log your workouts. Created using MERN stack.",
        description: "The project is a full-stack web application developed using the MERN stack along with Redux for state management. It incorporates register/login authentication and uses JWT (JSON Web Tokens) for authorization. The website allows users to create and manage exercises and workouts. Users can either publish their exercises to a global pool of exercises or save exercises from the pool. The created workouts can be logged with details such as date, sets, reps, and weight. Overall, the application provides a platform for users to track and manage their gym routines and progress effectively.",
        github: "https://github.com/reikal1337/mern-workout-log",
        preview: "https://workoutlog-zth5.onrender.com/",
        languages: "JavaScript/Html/Css",
        tags: "MERN/Redux/styled-components/MongoDB"
    },
    {
        id: nanoid(),
        name: "Scrabble (Java)",
        img: scrabbleImg,
        images: [],
        summary: "Scrabble game with server and client, created with Java.",
        description: "Java-based Scramble game with server-client architecture. Server handles game logic, utilizes Java sockets. Java Swing for GUI. Live chat feature. Multi-threaded. Requires minimum 2 players, supports multiple simultaneous games.",
        github: "https://github.com/reikal1337/Scrabble-Game",
        preview: undefined,
        languages: "Java",
        tags: "MVC/Sockets/Chat/Java Swing"
    },
    {
        id: nanoid(),
        name: "React page(React)",
        img: canaria.tourImg1,
        images: [canaria.tourImg2,canaria.tourImg3],
        summary: "My first react page.",
        description: "The project is a simple one-page website created using React. It is the first-ever React application I developed. It features sliding images and a feedback carousel. The website is responsive.",
        github: "https://github.com/reikal1337/tourism-canaria-page",
        preview: "https://reikal1337.github.io/tourism-canaria-page",
        languages: "JavaScript/Html/Css",
        tags: "React"
    },
    // {
    //     id: nanoid(),
    //     name: "4Scrabble",
    //     img: projectImg,
    //     images: [],
    //     summary: "S Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin lobortis condimentum libero eu tristique. Cras et felis lorem. Sed a arcu congue, porttitor nisl sed.",
    //     description: "D Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin lobortis condimentum libero eu tristique. Cras et felis lorem. Sed a arcu congue, porttitor nisl sed.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin lobortis condimentum libero eu tristique. Cras et felis lorem. Sed a arcu congue, porttitor nisl sed.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin lobortis condimentum libero eu tristique. Cras et felis lorem. Sed a arcu congue, porttitor nisl sed.",
    //     github: "https://github.com/",
    //     preview: "https://reikal1337.github.io/tourism-canaria-page/",
    //     languages: "Java/Html/Js",
    //     tags: "Web-Sockets/GUI/"
    // }
]
export default projectObjects