import styled, { keyframes } from "styled-components";
import { StyledCard } from "../styles/StyledCard.styles";

const animationImg = keyframes`
    0%{
        transform: rotateY(0deg);
    }
    100%{
        transform: rotateY(360deg);
    }

`

const animationCard = keyframes`
     0%{
        transform: scale(1.3) translate(-100vw);
        opacity: 1;
        box-shadow: 20px 15px 20px 0px black;
        }
    50%{
        transform: scale(1.3) translate(5vw);
        box-shadow: 20px 15px 20px 0px black;
        
    }
    50%{
        transform: scale(1.3) ;
        box-shadow: 20px 15px 20px 20px black;
    }
    90%{
        transform: scale(0.98) ;
        box-shadow: 5px 10px 20px 5px black;
    }
    100%{
        transform: scale(1) translate(0);
        box-shadow: 5px 10px 20px 10px black;
        opacity: 1;
    }

`


export const StyledLanguageCard = styled(StyledCard)`
    /* justify-content: center; */
    width: 150px;
    height: 200px;
    max-width: 300px;
    max-height: 300px;
    margin: 10px;
    overflow: visible;
    opacity: 0;
    transform: translate(-100vw);
    box-shadow: 5px 10px 20px 0px black;
    animation: 
    ${props => props.isAnimation ? animationCard : "none"} 3s ease-out 1 forwards;
    &:nth-child(2){
        animation-delay: 0.2s;
    }
    &:nth-child(3){
        animation-delay: 0.4s;
    }
    &:nth-child(4){
        animation-delay: 0.6s;
    }
    &:nth-child(5){
        animation-delay: 0.8s;
    }
    &:nth-child(6){
        animation-delay: 1s;
    }


   
    .rotate-langImg{
        animation: ${animationImg} 2s linear 1;
    }

    img {
        width: 120px;
        height: 100px;
        margin-top: 10px;
    }
    
    div{
        color: #d9dbdb;
        font-size: 12px;
    }

`