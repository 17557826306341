import img1 from "./workoutLog1.jpg"
import img2 from "./workoutLog2.jpg"
import img3 from "./workoutLog3.jpg"
import img4 from "./workoutLog4.jpg"
import img5 from "./workoutLog5.jpg"
import img6 from "./workoutLog6.jpg"
import img7 from "./workoutLog7.jpg"

import tourImg1 from "./canaria1.jpg"
import tourImg2 from "./canaria2.jpg"
import tourImg3 from "./canaria3.jpg"

import saga1 from "./saga1.jpg"
import saga2 from "./saga2.gif"
import saga3 from "./saga3.jpg"
import saga4 from "./saga4.jpg"
import saga5 from "./saga5.jpg"
import saga6 from "./saga6.jpg"

const workoutLog = {
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
}

const canaria = {
    tourImg1,
    tourImg2,
    tourImg3,
}

const saga = {
    saga1,
    saga2,
    saga3,
    saga4,
    saga5,
    saga6
}



export { workoutLog, canaria, saga }