import { SkillsSection, StyledContainer } from "./Skills.style"
import LanguageCard from "./LanguageCard"
import languageObjects from "./languageData"
import { useInView } from 'react-intersection-observer';


export default function Skills (props) {

    const [refObserver, inView] = useInView({
        threshold: 0.5,
        triggerOnce: true
    })
    return(
        <SkillsSection id={props.id} ref={refObserver}>
            <StyledContainer isAnimation={inView}>
                <h2>Skills</h2>
                <h3>Languages</h3>
                <div className="cards-container">
                    {
                        languageObjects.map(object => {
                            return <LanguageCard
                                className="cardTest"
                                key={object.id}
                                name={object.name}
                                image={object.image}
                                level={object.level}
                                isAnimation={inView}
                            />
                        })
                    }
                </div>
                <h3>Other</h3>
                <div className="other-text">
                    <p><b>Web development:</b> MERN stack (MongoDB, Express.js, React.js, Node.js), Next.js, Nest.js,Java Spring(basic), Redux, Tailwind, styled-components.</p>
                    <p><b>Database:</b> MongoDB, Prisma, Db modeling, Data normalization, semi-structional data, relational db.</p>
                    <p><b>Work frameworks/styles:</b> Agile(scrum), pair programming.</p>
                </div>
            </StyledContainer>
        </SkillsSection>
        
    )

}