import { AiFillGithub, AiFillCloseCircle } from 'react-icons/ai';
import { MdPreview } from 'react-icons/md';
import { StyledProjectCard, StyledProjectCardPopUp} from "./ProjectCard.styles"
import ImageSlider from './ImageSlider';

export default function ProjectCard(props) {
    function getSumary() {
        return (
            <>
            {props.summary} <a onClick={!props.popUp ? () => props.enableActive(props.index) : undefined} className='show-more' href='#background'>Show more...</a>
            </>
        )
    }

    const getSlides = () => {
        const slides = [props.img, ...props.images]
        return slides
    }

    function content() {
        return(
            <>
            {props.popUp && <AiFillCloseCircle className='exit-button' onClick={() => props.disableActive()} />}
            <div className='content-container' >
                {props.popUp  ? 
                <ImageSlider slides={getSlides()} alt={`${props.name} project`}/>
                :
                <img src={props.img} alt={props.name} /> 
                }

                <h4>{props.name}</h4>
                {props.loading && 
                <span className='loading-dis'>{props.loading}</span>
                }
                <p>{props.popUp ? props.description : getSumary()}</p>
                {props.popUp && <span><b>Languages: </b>{props.languages}</span>}
                {props.popUp && <span><b>Tags: </b>{props.tags}</span>}
                <div className='icons-container'>
                    {typeof props.github != "undefined" && <a href={props.github} target="_blank" rel="noreferrer"><AiFillGithub className='icon' title='Github'/></a>}
                    {typeof props.preview != "undefined" && <a href={props.preview} target="_blank" rel="noreferrer"><MdPreview className='icon' title='Preview'/></a>}
                </div>
            </div>
            </>
        )

    }
    return(
        <>
        {
            !props.popUp ?  
            <StyledProjectCard isAnimation={props.isAnimation} >{content()}</StyledProjectCard> 
            : 
            <StyledProjectCardPopUp> {content()}</StyledProjectCardPopUp>
        }
        </>
    )
}