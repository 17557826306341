import { useGLTF } from "@react-three/drei";

export function MySqlLogo(props) {
  const { nodes, materials } = useGLTF("models/mySqlLogo.gltf");
  return (
    <group ref={props.refs} position={[0,0,0]} rotation-y={props.startingPosition}>
        <group {...props} dispose={null} position={props.position} scale={props.scale}>
        <group scale={1.01}>
            <mesh
            geometry={nodes.Curve889.geometry}
            material={materials["SVGMat.027"]}
            />
            <mesh
            geometry={nodes.Curve889_1.geometry}
            material={materials["SVGMat.025"]}
            />
            <mesh
            geometry={nodes.Curve889_2.geometry}
            material={materials["SVGMat.026"]}
            />
        </group>
        </group>
    </group>
  );
}

useGLTF.preload("models/mySqlLogo.gltf");