import styled, { keyframes } from "styled-components";
import { StyledSection } from "../styles/StyledSection";

const animateH2 = keyframes`
    0%{
        opacity: 0;
        transform: skew(45deg) translate(-100vh);
    }
    40%{
        opacity: 1;
    }
    90%{
        transform: skew(0);
    }
    100%{
        opacity: 1;
        transform: translate(0);
    }

`
const animateContacts = keyframes`
    0%{
        opacity: 0;
        pointer-events: none;
    }
   
    100%{
        opacity: 1;
        transform: translate(0) ;
        pointer-events: visible;
        
    }
`

export const ContactsSection = styled(StyledSection)`
    flex-direction: column;
    height: 100vh;
    
    
    h2{
        opacity: 0;
        margin-bottom: 20px;
        transform: skew(45deg) translate(-100vh);
        animation: ${animateH2} 2s ease-out 0s 1 forwards;
        
    }
    div{
        display: flex;
        flex-direction: column;
        align-items: center;
        
    }
    a:nth-child(1){
        transform: translate(-90vh);
        
    }
    a:nth-child(2){
        transform: translate(90vh);
        animation-delay: 1.2s;
    }
    a:nth-child(3){
        transform: translate(-90vh);
        animation-delay: 1.4s;
    }
    a{
        margin-bottom: 5px;
        color: #d9dbdb;
        color: whitesmoke;
        font-weight: 600;
        font-size: 20px;
        opacity: 0;
        pointer-events: visible;
        animation: ${animateContacts} 2s ease-out 1s 1 forwards;
        transition: 0.3s ease !important;
        
        &:hover{
            scale: 1.2;
            #icon-linkedin{
                color: #0077b5;
            }
            
        }
    }
   





`