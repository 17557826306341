import styled, { keyframes } from "styled-components";

const sentAnimation = keyframes`
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
`

export const StyledForm = styled.div`
color: white;
margin: 20px 10px;
z-index: 1;

    form{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
    }
    label{
        display: flex;
        flex-direction: column;
        margin-top: 10px;
    }

    button{
        border: 3px outset grey;
        border-radius: 10px;
        font-size: 15px;
        margin-top: 20px;
        color: white;
        padding-block: 5px;
        padding-inline: 7px;
        background-color: rgb(50,50,50);
        cursor: pointer;
        
        &:hover{
            background-color: rgb(80,80,80);
        }
        &:active{
            box-shadow: inset 1px 1px 10px 1px black;
        }
        &:disabled{
            box-shadow: inset 1px 1px 10px 1px black;
            color: grey;
        }
    }
    textarea{
        resize: none;
    }

    input, textarea{
        border-radius: 10px;
        width: 250px;
        padding: 5px;
        box-shadow: inset 1px 1px 10px 1px black;

    }

    .contact-sentIcon{
        color: green;
        opacity: 0;
        animation: ${sentAnimation} 2s 1 ease-out forwards;
    }

`