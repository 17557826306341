import {useEffect,useState} from 'react'

 function useScrollProgress()  {
    const [scrollPosition, setScrollPosition] = useState(0)

    const maxScroll = Math.max( document.body.scrollHeight, document.body.offsetHeight, 
        document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight );
        
    const handleScroll = () =>{
      const position = window.pageYOffset;
      
      setScrollPosition(prevState => prevState = position)
    }
  
    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            window.removeEventListener('scroll', handleScroll);
            };
      }, []);
    
  return Math.floor((100 * scrollPosition) / maxScroll)
}

export default useScrollProgress