import styled, { keyframes } from "styled-components"
import {ContentContainer} from "../styles/ContentContainer"
import { animationAbout } from "./About.styles"
import { deviceSize } from "../styles/deviceSize"

const blinkAnimation = keyframes`
  0%{
    opacity: 1
  }
  50%{
    opacity: 0
  }
  100%{
    opacity: 1
  }

`

export const InformationContainer = styled(ContentContainer)`
  max-width: 650px;
  padding: 10px;
  margin-inline: 15px;
  opacity: 0;
  transform: translateX(-100%);
  animation:
  ${props => props.isAnimation ? animationAbout : "none"}  2s ease-out 0s 1 forwards;

  h2{
    margin: 0px 0 10px 10px;
    text-align: center;
  }
  .typewriter-container{
    position: relative;
    p{
      position: absolute;
      top: 0;
      left: 0;
      margin: 0 10px 10px 5px ;
      color: whitesmoke;
      padding: 12px;
      z-index: 2;
      &::after{
        content: "";
        border-right: 1px solid whitesmoke;
        animation: ${blinkAnimation} 0.5s linear infinite;

      }
    }
    
    .invisible-text{
      position: relative;
      z-index: 0;
      border: 3px inset black;
      background-color: rgb(50,50,50);
      box-shadow: inset 1px 1px 10px 1px black;
      color: rgba(0,0,0,0);
      user-select: none;
      &::after{
        content: "";
        border: none;
        animation: none;
      }
    }
  }
  
  @media ${deviceSize.mobileL} {
        p{
          font-size: 12px;
        }
        
  }
`



