import { ProjectsSection, StyledContainer, StyledBackground } from "./Projects.styles"
import ProjectCard from "./ProjectCard"
import projectObjects from "./projectData"
import { useState } from "react"
import { useInView } from "react-intersection-observer"
import ProjectCardPopUp from "./ProjectCardPopUp"

export default function Projects(props) {
    const [popUp, setPopUp] = useState(false)
    const [activeIndex,setActiveIndex] = useState("")

    const [refObserver, inView] = useInView({
        threshold: 0.5,
        triggerOnce: true
    })

    function enableActive(index){
        setActiveIndex(prevState => index)
        setPopUp(prevState => true)
        document.body.style.overflow = 'hidden'
    }
    function disableActive(){
        setActiveIndex(prevState => "")
        setPopUp(prevState => false)
        document.body.style.overflow = 'visible'
    }
    return(
        <ProjectsSection id={props.id} ref={refObserver}>
            <StyledContainer isAnimation={inView}>
                <h2>Projects</h2>
                <div className="card-container">
                    {
                        projectObjects.map((object,i) =>{
                            return (
                                <ProjectCard 
                                    key={object.id}
                                    id={object.id}
                                    index={i}
                                    {...object} 
                                    enableActive={enableActive}
                                    popUp={false}
                                    isAnimation={inView}
                                />
                            )
                        })
                    }
                </div>
            </StyledContainer>
                {popUp &&
                        
                        <>
                        <ProjectCardPopUp index={activeIndex} disableActive={disableActive} />      
                        <StyledBackground id="background"  onClick={() => disableActive() }/>
                        </>

                            
                    
                    }
        </ProjectsSection>
        
    )
}
