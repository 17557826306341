import styled, { keyframes } from "styled-components"
import { deviceSize } from "../styles/deviceSize"

const animation = keyframes`
    0%{
        opacity: 0;
        transform: translateX(-100px) rotate(90deg);
    }
    100%{
        opacity: 1;
        transform: translateX(0) rotate(0);
    }
`

export const TitleAnimation  = styled.h1`
    color: whitesmoke;
    span {
        display: inline-block;
        opacity: 0;
        animation: 1s ease-out 1s 1 ${animation} forwards;
        
    }
    span:nth-child(1){
        animation-delay: 1.4s;
    }

    span:nth-child(2){
        animation-delay: 1.5s;
    }

    span:nth-child(3){
        animation-delay: 1.6s;
    }

    span:nth-child(4){
        animation-delay: 1.7s;
    }

    span:nth-child(5){
        animation-delay: 1.8s;
    }
    span:nth-child(6){
        animation-delay: 1.9s;
    }

    span:nth-child(7){
        animation-delay: 1.9s;
    }

    span:nth-child(8){
        animation-delay: 2s;
    }

    span:nth-child(9){
        animation-delay: 2.1s;
    }

    span:nth-child(10){
        animation-delay: 2.2s;
    }
    span:nth-child(11){
        animation-delay: 2.3s;
    }

    span:nth-child(12){
        animation-delay: 2.4s;
    }

    @media ${deviceSize.tablet} {
        span{
            font-size: 20px;
        }
    }
    @media ${deviceSize.mobileM} {
        span{
            font-size: 18px;
        }
    }

`