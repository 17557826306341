import styled from "styled-components";

export const StyledBackground2d = styled.div`
    z-index: 0;
    /* background: radial-gradient(#1b2735 0%, #090a0f 100%); */
    background: radial-gradient(#171717 0%, #000000 100%);

    height: 100vh !important;
    width: 100vw !important;
    position: fixed;

`