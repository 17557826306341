import { useEffect, useRef, useState } from "react"

function TypeWriterEffect(props) {
    const index = useRef(0)
    const [displayedText, setDisplayedText] = useState("")

    useEffect(() => {
        let currentIndex = index.current

        const timerId = setTimeout(() => {    
            setDisplayedText((prevState) => 
                prevState + props.text.charAt(currentIndex)
            )
            index.current += 1
        }, 10)
        return () => {
            clearTimeout(timerId)
        }
    }, [displayedText,props.text])

  return (
    <div className="typewriter-container">
        <p className="invisible-text">{props.text}</p>
        <p>{displayedText}</p>
    </div>
  )
}

export default TypeWriterEffect