import { useGLTF } from "@react-three/drei";

export function HtmlLogo(props) {
  const { nodes, materials } = useGLTF("models/htmlLogo.gltf");
  return (
    <group ref={props.refs} position={[0,0,0]} rotation-y={props.startingPosition}>
        <group {...props} dispose={null} position={props.position} scale={props.scale}>
        <mesh
            geometry={nodes.path2_1.geometry}
            material={materials["SVGMat.003"]}
        />
        <mesh
            geometry={nodes.path2_2.geometry}
            material={materials["SVGMat.001"]}
        />
        <mesh
            geometry={nodes.path2_3.geometry}
            material={materials["SVGMat.002"]}
        />
        <mesh
            geometry={nodes.path2_4.geometry}
            material={materials["SVGMat.004"]}
        />
        </group>
    </group>
  );
}

useGLTF.preload("models/htmlLogo.gltf")