import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    ::-webkit-scrollbar{
        width: 10px;

    }
    ::-webkit-scrollbar-track{
        background: rgb(50,50,50);
        

    }
    ::-webkit-scrollbar-thumb{
        background: rgb(100,100,100);
        border-radius: 10px;

    }
    ::-webkit-scrollbar-thumb:hover{
        background: rgb(160,160,160);

    }



    *{
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        font-family: 'Merienda', cursive;
        font-family: 'Playfair Display', serif;
        scrollbar-color: rgb(100,100,100) rgb(50,50,50);
        scrollbar-width: thin;
    }
    html, body { 
        height: 100%;
        
    }
    
    a{
        text-decoration: none;
    }

`