import { AboutSection } from "./About.styles"
import { useInView } from 'react-intersection-observer';
import { InformationContainer } from "./Information.style"
import Canvas2d from "./canvas2d/Canvas2d"
import { PortraitContainer } from "./Portrait.style"
import TypeWriterEffect from "./TypeWriterEffect";

export default function About(props) {
    const [refObserver, inView] = useInView({
        threshold: 0.5,
        triggerOnce: true
    })
    return(
        <AboutSection id={props.id} ref={refObserver}>
            <PortraitContainer isAnimation={inView}>
                 {inView && <Canvas2d trigerReset={inView} />}            
            </PortraitContainer>
            <InformationContainer isAnimation={inView}>
                <h2>About</h2>
                {inView &&
                    <TypeWriterEffect 
                        text={
                            `Hey there! I am an aspiring software/web developer currently part time studying Programming and Multimedia.  
                            As a “Junior” programmer, I'm all set to embark on my coding adventures. I've got a solid foundation in programming principles and an insatiable thirst for knowledge, ready to grow alongside the pros.
                            Coding has always fascinated me because it's like having a superpower that brings ideas to life in digital wonders. From crafting websites with HTML, CSS, and JavaScript to diving into backend development with Java and JavaScript, I'm excited to expand my tech skills and become a coding master.
                            When I'm not busy tinkering with code, you'll find me pounding the pavement. Running/jogging are my main hobbies, providing the endurance and discipline I bring to my work. Just like conquering that last mile, I'm all about continuous improvement and tackling challenges head-on.
                            With my programming passion and hunger for growth, I'm ready to dive into projects, collaborate with amazing teams, and make waves in the world of programming.`
                        } 
                    />
                }
            </InformationContainer>
        </AboutSection>
        
    )
}
