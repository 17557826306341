import { Canvas } from '@react-three/fiber'
import styled from 'styled-components';

export const Canvas3dStyled = styled(Canvas)`
    height: 100vh !important;
    width: 100vw !important;
    display: block;
    position: fixed !important;
    
    top: 0;
    left: 0;
    z-index: -99;
    background-size: cover;
    background-color: black;
`