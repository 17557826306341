import styled, { keyframes, css } from "styled-components";
import { deviceSize } from "../styles/deviceSize";
import { Link } from 'react-scroll'



const openAnimation = keyframes`
    0%{
        width: 0%;
    }
    100%{
        width: 100%;
    }
`
const closeAnimation = keyframes`
    0%{
        width: 100%;
    }
    100%{
        width: 0%;
    }
`

const openLinkAnimation = keyframes`
    0%{
        transform: rotate(-90deg) ;
        
    }
    100%{
        transform: rotate(0deg);
    }
`
const closeLinkAnimation = keyframes`
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(-90deg);
    }
`


export const SideNavBarStyled = styled.nav`
    --animationTime: 1s;
    display: flex;
    position: fixed;
    height: 100%;
    min-width: 200px;
    top: 0;
    left: 0;
    overflow-x: hidden;
    z-index: 10;
    ul{ 
        
        width: 0px;
        display: flex;
        flex-direction: column;
        padding: 0px;
        justify-content: space-evenly;
        align-items: center;
        background: rgb(65,65,65);
        overflow-x: hidden;
        background: linear-gradient(0deg, rgba(65,65,65,0.3) 0%, rgba(105,105,105,0.3) 100%);
        backdrop-filter: blur(2px);
        animation: ${props => props.open  ? openAnimation : closeAnimation} var(--animationTime) linear 0s 1 forwards;
        ${
            props => props.open === null ? css`animation: none;` : "" 
        }
        img{
            border: 3px inset black;
            border-radius: 10px;
            box-shadow: inset 1px 1px 10px 1px black;
            background-color: rgb(50,50,50);
            padding: 5px 7px;
        }
    };

    
        
    
    a{
        transform: rotate(-90deg);
        /* display: block; */
        font-size: 20px;
        font-weight: 800;
        color: whitesmoke;
        cursor: pointer;
        
        animation: ${props => props.open ? openLinkAnimation : closeLinkAnimation} var(--animationTime) linear 0s 1 forwards;
        ${
            
            props => props.open === null ? css`animation: none;` : "" 
        }

        transition: 0.2s linear;
        &:hover{
            color: grey;
            scale: 1.2;
        }
        
    }
    .active{
        border: 3px inset black;
        border-radius: 10px;
        box-shadow: inset 1px 1px 10px 1px black;
        background-color: rgb(50,50,50);
        padding: 5px 7px;
        }
    
    .vl{
        height: 100%;
        border-left: 2px outset grey;
    }
    .icon-arrow{
        color: whitesmoke;
        font-size: 40px;
        display: flex;
        align-self: center;
        margin-left: 5px;
        transition: 0.2s linear;
        ${props =>props.open ?
            css`transform: rotate(0deg);` :
            css`transform: rotate(180deg);`
        }
        
        &:hover{
            color: grey;
            scale: 1.2;
        }

    }

    @media ${deviceSize.tablet} {
        .icon-arrow{
            font-size: 20px;
            color: #660000;
            &:hover{
                color: #660000;
                scale: 1.2;
            }
        }
        a{
            font-size: 16px;
        }
        .active{
            padding:2px;
        }
        
  }
  

`

export const LinkStyled = styled(Link)`

`