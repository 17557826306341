import { ContactsSection } from "./Contacts.styles"
import { FaLinkedin } from 'react-icons/fa'
import GmailIcon from "./GmailIcon"
import { AiFillGithub } from 'react-icons/ai';
import { useState } from "react"
import { useInView } from "react-intersection-observer"
import ContactsForm from "./ContactsForm";




function Contacts(props) {
  const [gmailHovered,setGmailHovered] = useState(false)
  const [refObserver, inView] = useInView({
    threshold: 0.7,
    triggerOnce: true
  })
  return (
    <ContactsSection id={props.id} ref={refObserver}>
      {inView &&
        <>
        <h2>You can find me here:</h2>
        <div>
          <a href="mailto:reikal951@gmail.com" target="_blank" rel="noreferrer" onMouseEnter={() => setGmailHovered(true)} onMouseLeave={() => setGmailHovered(false)} ><GmailIcon isHovered={gmailHovered} /> reikal951@gmail.com</a>
          <a href="https://www.linkedin.com/in/lukas-reika" target="_blank" rel="noreferrer"><FaLinkedin id="icon-linkedin" /> Linkedin</a>
          <a href="https://github.com/reikal1337" target="_blank" rel="noreferrer"><AiFillGithub id="footer-github" />Github</a>
        </div>
        </>
      }
      <ContactsForm />
    </ContactsSection>
    //#4285F4
    
  )
}

export default Contacts