import Particle from "./canvasParticle"

export default class Effect{
    constructor(canvas,image){
        this.width = canvas.width
        this.height = canvas.height
        this.particlesArray = [];
        this.image = image
        this.centerX = this.width * 0.5
        this.centerY = this.height * 0.5
        this.x = this.centerX - this.image.width * 0.5
        this.y = this.centerY - this.image.height * 0.5
        this.gap = 3; 
        this.mouse = {
            radius: 1000,
            x: undefined,
            y: undefined
        }
        canvas.addEventListener('mousemove', event =>{
            var rect = canvas.getBoundingClientRect()
            this.mouse.x = event.x - rect.left
            this.mouse.y = event.y - rect.top
        })
    }
    init(context){
        context.drawImage(this.image, this.x, this.y)
        const pixels = context.getImageData(0, 0, this.width, this.height).data
        for (let y = 0; y < this.height; y += this.gap) {
            for (let x = 0; x < this.width; x += this.gap) {
                const index = (y * this.width + x) * 4  
                const alpha = pixels[index + 3]
                const lightness = this.#greyScale(pixels[index], pixels[index + 1], pixels[index + 2]) 
                const color = "rgb(" + lightness + "," + lightness + "," + lightness + ")"
                if(alpha > 0){
                    this.particlesArray.push(new Particle(this, x, y, color))
                }
            }
            
        }
    
    }
    draw(context){
        this.particlesArray.forEach(particle => particle.draw(context))
        
    }
    update(){
        for (let index = 0; index < this.particlesArray.length; index++) {
            this.particlesArray[index].update()
        }
    }
    warp(){
        this.particlesArray.forEach(particle => particle.warp())
    }
    #greyScale(red,green,blue){
        return parseInt(red * .299 + green * .587 + blue * .114);
    }
    
}