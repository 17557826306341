import { AiFillGithub, AiFillCloseCircle } from 'react-icons/ai';
import { MdPreview } from 'react-icons/md';
import { StyledProjectCardPopUp } from "./ProjectCard.styles"
import ImageSlider from './ImageSlider';
import projectObjects from './projectData';

export default function ProjectCardPopUp(props) {
    const project = projectObjects[props.index]
    const getSlides = () => {
        const slides = [project.img, ...project.images]
        return slides
    }
    return(
        <StyledProjectCardPopUp>
            <AiFillCloseCircle className='exit-button' onClick={() => props.disableActive()} />
            <div className='content-container' >
                
                <ImageSlider slides={getSlides()} alt={`${project.name} project`}/>

                <h4>{project.name}</h4>
                {project.loading && 
                <span className='loading-dis'>{project.loading}</span>
                }
                <p>{project.description}</p>
                <span><b>Languages: </b>{project.languages}</span>
                <span><b>Tags: </b>{project.tags}</span>
                <div className='icons-container'>
                    {typeof project.github != "undefined" && <a href={project.github} target="_blank" rel="noreferrer"><AiFillGithub className='icon' title='Github'/></a>}
                    {typeof project.preview != "undefined" && <a href={project.preview} target="_blank" rel="noreferrer"><MdPreview className='icon' title='Preview'/></a>}
                </div>
            </div>
        </StyledProjectCardPopUp>
        
    )
}