import styled, { keyframes } from "styled-components"
import { deviceSize } from "../styles/deviceSize"

const animation = keyframes`
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
        transform: translateX(0) rotate(0);
    }
`
export const ParagraphAnimation = styled.p`
color: #d9dbdb;
    span{
        display: inline-block;
        opacity: 0;
        animation: 1s ease-out 0s 1 ${animation} forwards;
    }
    span:nth-child(1){
        transform: translateX(-50px);
        animation-delay: 3s;
    }

    span:nth-child(2){
        transform: translateY(-50px) rotate(90deg);
        animation-delay: 3.1s;
    }

    span:nth-child(3){
        transform: translateY(50px) rotate(90deg);
        animation-delay: 3.2s;
    }

    span:nth-child(4){
        transform: translateY(-50px) rotate(90deg);
        animation-delay: 3.3s;
    }

    span:nth-child(5){
        transform: translateY(50px) rotate(90deg);
        animation-delay: 3.4s;
    }
    span:nth-child(6){
        transform: translateY(-50px) rotate(90deg);
        animation-delay: 3.5s;
    }

    span:nth-child(7){
        transform: translateY(50px) rotate(90deg);
        animation-delay: 3.6s;
    }

    span:nth-child(8){
        transform: translateY(-50px) rotate(90deg);
        animation-delay: 3.7s;
    }

    span:nth-child(9){
        transform: translateY(50px) rotate(90deg);
        animation-delay: 3.8s;
    }

    span:nth-child(10){
        transform: translateX(50px);
        animation-delay: 3.9s;
    }
    @media ${deviceSize.tablet} {
        span{
            font-size: 12px;
        }
    }
    @media ${deviceSize.mobileM} {
        span{
            font-size: 10px;
        }
    }
`