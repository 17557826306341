import javaImg from "../../images/javaImg.png"
import pythonImg from "../../images/pythonImg.png"
import jsImg from "../../images/jsImg.png"
import cssImg from "../../images/cssImg.png"
import sqlImg from "../../images/sqlImg.png"
import tsImg from "../../images/tsImg.png"
import { nanoid } from "nanoid"


 const languageObjects = [
    {
        id: nanoid(),
        name: "Java",
        image: javaImg,
        level: "2"
    },
    {
        id: nanoid(),
        name: "JavaScript",
        image: jsImg,
        level: "2"
    },
    {
        id: nanoid(),
        name: "TypeScript",
        image: tsImg,
        level: "2"
    },
    {
        id: nanoid(),
        name: "Css",
        image: cssImg,
        level: "2"
    },
    {
        id: nanoid(),
        name: "Python",
        image: pythonImg,
        level: "1"
    },
    {
        id: nanoid(),
        name: "MySql/NoSql",
        image: sqlImg,
        level: "1"
    }

]

export default languageObjects