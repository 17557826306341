import React, { useState } from 'react'
import { StyledForm } from './ContactsForm.styles'
import emailjs from '@emailjs/browser';
import { GiCheckMark } from "react-icons/gi"

function ContactsForm() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",

  })
  const [loading, setloading] = useState(false)
  const [ sent, setSent] = useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target

    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(formData)
    setloading(true);

    emailjs.send('service_n0iby3g', 'template_830sli1', formData,"fySztlI9k7EvBEH-2")
      .then((result) => {
        setSent(true)
      }, (error) => {
          setloading(false)
      });
  }
  return (
    <StyledForm>
      {sent ?
      <>
      <GiCheckMark className='contact-sentIcon' size={150}/>
      <h4 >Messgae sent!</h4>
      </>
      :
      <form onSubmit={handleSubmit}>
        <label htmlFor="name">
          Name: 
          <input 
            value={formData.name}
            onChange={handleChange}
            placeholder='Name...'
            id='name'
            name='name'
            type='text'
            required

          />
        </label>
        <label htmlFor="email">
          Email: 
          <input 
            value={formData.email}
            onChange={handleChange}
            placeholder='Email@something.com...'
            id='email'
            name='email'
            type='email'
            required
          />
        </label>
        <label htmlFor="message">
          Message: 
          <textarea 
            value={formData.message}
            onChange={handleChange}
            placeholder='Message...'
            id='message'
            name='message'
            rows={8}
            required

          />
        </label>
        <button 
          type='submit'
          disabled={loading}
          >
          Send
          </button>
      </form>
      }
    </StyledForm>
  )
}

export default ContactsForm