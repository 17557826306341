import styled, { keyframes } from "styled-components";
import { StyledSection } from "../styles/StyledSection";
import { ContentContainer } from "../styles/ContentContainer";
import { deviceSize } from "../styles/deviceSize";


const animationContainer = keyframes`
    0%{
        opacity: 1;
        transform: scaleY(0);
    }
    100%{
        opacity: 1;
        transform: scaleY(1);
        
    }

`

const animationCard = keyframes`
    0%{
        opacity: 0;
        transform: scale(0.2) rotate(180deg);
    }
    100%{
        opacity: 1;
        transform: scale(1) rotate(0deg);
    }

`

const animationBackground = keyframes`
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
`


export const ProjectsSection = styled(StyledSection)`
    @media ${deviceSize.customS} {
        height: 100%;
        
  }

`
export const StyledContainer = styled(ContentContainer)`
    width: 800px;
    margin-inline: 15px;
    opacity: 0;
    transform: scale(0.2) rotate(180deg);
    
    animation: 
    ${props => props.isAnimation ? animationContainer : "none"} 1.5s ease-out 0s 1 forwards;
    /* height: 70px; */
    h2{
        margin: 10px 0 10px 0;
        text-align: center;
    }
    .card-container{
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        opacity: 1;
        margin: 10px 0 10px 0;
        opacity: 1;
        /* animation: ${animationCard} 2s ease-out 1s 1 forwards; */
    }

`

export const StyledBackground = styled.div`
    width: 100%;
    height: 100%;
    z-index: 1;
    position: absolute;
    background-color: rgba(0,0,0,0.7);
    opacity: 0;
    animation: ${animationBackground} 0.5s ease-out 0s 1 forwards;
`