import styled from "styled-components"




function SlideButton(props) {

  const handleClick = (is3d) => {
    if(props.is3d !== is3d){
      props.toggle3d(is3d)
    }
    
  }

  return (
    <StyledSlideBtton>
      <span onClick={() => handleClick(true)} className={props.is3d ? "active3d" : ""}>3D</span>
      <span onClick={() => handleClick(false)} className={!props.is3d ? "active3d" : ""}>2D</span>
    </StyledSlideBtton>
  )
}

export default SlideButton

const StyledSlideBtton = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 10;
  font-size: 20px;
  color: white;
  font-weight: 800;
  display: flex;
  flex-direction: column;
  
  span{
    text-shadow: 1px 1px 1px whitesmoke;
    cursor: pointer;
    transition: color 0.5s ease;
    &:hover{
      color: #0e3d07;
    }
  }

  .active3d{
    color: #0e3d07;
  }
  
  

`