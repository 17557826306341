import { useRef, useEffect } from "react"
import { myPortrait } from "../../../images/myPortrait"
import { myPortraitSmall } from "../../../images/myPortraitSmall" 
import Effect from "./canvasEffect"
import { CanvasStyled, ImageStyled, ButtonStyled } from "./Canvas2d.styles"
import { useState } from "react"

const portraitImg = myPortrait
const portraitImgSmall = myPortraitSmall
let haveReset = false;

export default function Canvas2d(props) {

    const canvasRef = useRef(null)
    const imageRef = useRef(null)
    let effect;
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [toggleReset, setToggleReset ] = useState(false)
    
    

    function draw(ctx,effect){
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
        effect.draw(ctx)
        effect.update()
    }
    
    
    useEffect(() => {

        function handleWindowResize(){
            setWindowWidth(prevState => prevState = window.innerWidth)
        }

        window.addEventListener('resize', handleWindowResize);


        const canvas = canvasRef.current
        canvas.width = imageRef.current.width;
        canvas.height = imageRef.current.height;
        
        const ctx = canvas.getContext("2d", { willReadFrequently: true })
        let animationFrameId
        effect = new Effect(canvas,imageRef.current);
        effect.init(ctx)
        
        function animate(){
            draw(ctx,effect)
            animationFrameId = window.requestAnimationFrame(animate)
        }
        animate()
        if(props.trigerReset && !haveReset){
            handleReset()
            haveReset = true;
        }

        return () => {
            window.cancelAnimationFrame(animationFrameId)
            window.removeEventListener('resize', handleWindowResize);
        }

    },[windowWidth,toggleReset, props.trigerReset])
    
    function handleReset(){
        setToggleReset(prevState => !prevState)
        
    }

    return(
        <>
            <CanvasStyled ref={canvasRef} /> 
            <ImageStyled  
            src={windowWidth > 500 ? portraitImg : portraitImgSmall}
             alt="portrait"  ref={imageRef}/>
            <ButtonStyled onClick={ () => handleReset()}>Reset</ButtonStyled>
        </>
    )
}