import { Html, useProgress } from "@react-three/drei"
import styled from "styled-components"



export default function Loader(props) {
    const { progress } = useProgress()
    
    if(Math.floor(progress) === 100){
        props.isLoaded(true);
    }
    return(
        <StyledLoader center>
            <div>Loading...</div>
            <div >{Math.floor(progress)}%</div>
        </StyledLoader>
    )
}

const StyledLoader = styled(Html)`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: radial-gradient(#171717 0%, #000000 100%);
    background-size: cover;
    width: 100vw ;
    height: 100vh;

    div{
        color: whitesmoke;
        font-size: 32px;
        font-weight: 600;
    }
`