import { ParagraphAnimation } from "./AnimatedParagraph.style"

export default function AnimatedParagraph() {
    const textArray = "My name is Lukas and I am junior Software Developer.".split(" ")

    
    return(
        <ParagraphAnimation>{
            textArray.map((word, index) => (
                <span key={index}>{word + "\u00A0"}</span>
            ))
            }
        </ParagraphAnimation>
    )
    
}