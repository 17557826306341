import styled from "styled-components";

export const StyledProgressBar = styled.div`
        display: flex;
        background-color: grey;
        width: 80%;
        height: 17px;
        border-radius: 10px;
        margin: 5px;
        border: 2px solid black;

`
 export const StyledProgress = styled.div`
        background: ${props => props.color};
        border-radius: ${props => props.border} ;
        width: 25%;
        height: 13px;

`
