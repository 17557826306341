import styled from "styled-components"

export const CanvasStyled = styled.canvas`
    position: absolute;
    /* width: 400px;
    height: 400px; */
    z-index: 0;
    /* width: 50px;
    height: 50px; */
    @media (max-width: 500px) {
    /* width: 250px;
    height: 250px; */
    /* width: 50px;
    height: 50px; */
        
  }
    
`

export const ImageStyled = styled.img`
    
    z-index: -1;
    box-shadow: inset 1px 1px 10px 1px black;
    background-color: rgb(50,50,50);
    width: 400px;
    height: 400px;

    user-drag: none;  
    user-select: none;
   -moz-user-select: none;
   -webkit-user-drag: none;
   -webkit-user-select: none;
   -ms-user-select: none;

   @media (max-width: 500px) {
    width: 250px;
    height: 250px;
        
  }
`

export const ButtonStyled = styled.button`
    position: absolute;
    z-index: 5;
    top: 5px;
    left: 5px;
    border: 3px outset grey;
    border-radius: 10px;
    color: whitesmoke;
    padding: 5px;
    background-color: rgb(50,50,50);
    cursor: pointer;
    
    &:hover{
        background-color: rgb(80,80,80);
    }
    &:active{
        box-shadow: inset 1px 1px 10px 1px black;
    }




`