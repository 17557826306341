import styled from "styled-components";

export const StyledCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(64,64,64);
    border: 4px inset black;
    border-radius: 10px;
    box-shadow: 5px 10px 20px 10px black;
`