import HoverAnimation from "./HoverAnimation"
import { StyledLanguageCard } from "./LanguageCard.styles"
import ProgressBar from "./ProgressBar"

export default function Language ({name,image, level, isAnimation}) {
    function levelText(level){
        switch (level) {
            case "1":
                return "Basic"
            case "2":
                return "Intermediate"
            case "3":
                return "Professional"
            case "4":
                return "Expert"
            default:
                break;
        }
    }
    return(
        <StyledLanguageCard isAnimation={isAnimation} >
            <HoverAnimation animationClass={"rotate-langImg"}>
                <img src={image} alt={name} />
            </HoverAnimation>
            
            <span>{name}</span>
            <ProgressBar level={level}/>
            <div >{
            levelText(level)
            }</div>
        </StyledLanguageCard>
    )
}