import styled from "styled-components"


export const StyledFooter = styled.footer`
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        width: 100%;
        height: 50px;
        background-color: rgb(50,50,50);
        color: whitesmoke;
        border-top: 8px inset #666666;
        scroll-snap-align: start;

    a{
        color: whitesmoke;
        margin-left: 5px;
        font-size: 10px;
        text-decoration: wavy underline;
        
        &:hover{
            color: grey;
            
        }
    }
    .footer-icons{
        display: flex;
        align-items: center;
        margin-left: 20px;
        #footer-gmail,#footer-github,#footer-linkedin{
            font-size: 20px;
            &:hover{
                scale: 1.1;
            }
        }
        #footer-linkedin{
            color: #0077b5;
        }
        
    }
    
    

`