import styled, { keyframes } from "styled-components";
import { StyledSection } from "../styles/StyledSection";
import { deviceSize } from "../styles/deviceSize";

export const AboutSection = styled(StyledSection)`
    flex-direction: row;
    flex-wrap: wrap-reverse;
    @media ${deviceSize.customS} {
        height: 100%;
        
  }

`

export const animationAbout = keyframes`
  0%{
    opacity: 0;
    transform: translateX(-100%);
  }
  80%{
    transform: translateX(5%);
  }
  100%{
    opacity: 1;
    transform: translateX(0%);
  }
`