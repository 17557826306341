import styled from "styled-components"
import { deviceSize } from "./deviceSize"

export const StyledSection = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    color: whitesmoke;
    height: 100vh;
    overflow-x: hidden;
    @media ${deviceSize.customS} {
        height: 100%;
        
  }
    /* height: 100dvh; */
    /* width: 100vw; */
    /* width: 100dvw; */
`

export const StyledSectionSpacer = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

`