import { StyledBackground2d } from "./Background2d.styles"
import { useCallback } from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

function Background2d() {

    const particlesInit = useCallback(async engine => {
        await loadFull(engine);
    }, []);

return (
  <StyledBackground2d>
    <Particles        
        init={particlesInit}
        options={{
            fpsLimit: 120,
            interactivity: {
                detectsOn: 'canvas',
                events: {
                    onHover: {
                        enable: true,
                        mode: "repulse",
                        parallax: {
                            enable: true,
                            force: 10,
                            smooth: 10,
                        }
                    },
                    resize: true,
                },
                modes: {
                    repulse: {
                        distance: 50,
                        duration: 0.4,
                        easing: "ease-out-quad",
                        factor: 30,
                        maxSpeed: 2,
                        speed: 0.5,
                        }
                        
                },
            },
            particles: {
                color: {
                    value: "#ffffff",
                },
                collisions: {
                    enable: true,
                },
                move: {
                    direction: "none",
                    enable: true,
                    outModes: {
                        default: "bounce",
                    },
                    random: true,
                    speed: 1,
                    straight: false,
                },
                number: {
                    density: {
                        enable: true,
                        area: 600,
                    },
                    value: 100,
                },
                opacity: {
                    value: 1,
                },
                shape: {
                    type: "circle",
                },
                size: {
                    value: { min: 0.1, max: 2 },
                },
            },
            detectRetina: true,
        }}
    />
    </StyledBackground2d>
    )
}

export default Background2d

