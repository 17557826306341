import styled from "styled-components"
import { ContentContainer } from "../styles/ContentContainer"
import { animationAbout } from "./About.styles"
import { deviceSize } from "../styles/deviceSize"


export const PortraitContainer = styled(ContentContainer)`
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 500px;
    max-height: 500px;
    padding: 10px;
    margin: 20px 20px 0 0;
    opacity: 0;
    transform: translateX(-100%);
    animation: 
    ${props => props.isAnimation ? animationAbout : "none"} 2s ease-out 1s 1 forwards;

    @media ${deviceSize.customS} {
        margin: 20px 10px 10px 10px;
        
  }
    
    
`