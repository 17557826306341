import { TitleAnimation } from "./AnimatedTitle.style"


export default function AnimatedTitle(){
    const textArray = "Hello World!".split("")
    return(
        <TitleAnimation >{
            textArray.map((letter, index) => (
                <span key={index}>{letter !== " " ? letter : "\u00A0" }</span>
            ))}
        </TitleAnimation>
    )
}