import { useState } from 'react'
import { StyledSlider} from './ImageSlider.styles'
import { IoIosArrowBack, IoIosArrowForward} from "react-icons/io"
import { nanoid } from "nanoid"

function ImageSlider(props) {
    const [ index, setIndex] = useState(0)

    const toPreviousSlide = () => {
        const newIndex = index === 0 ? props.slides.length - 1 : index - 1
        setIndex(newIndex)
    }
    const toNextSlide = () => {
        const newIndex = index === props.slides.length - 1 ? 0 : index + 1
        setIndex(newIndex)
        
    }

  return (
    <StyledSlider>
        {props.slides.map((slide,i) => {
          return <img 
            key={nanoid()}
            srcSet={slide}
            className={i !== index ? "slide" : "slide-animation"}
            alt={props.alt}
            />
        })}
        
        {props.slides.length > 1 && <div className='slider-controls'>
          <IoIosArrowBack className='slider-button' onClick={toPreviousSlide} />
          <div className='slider-counter'>{`${index + 1} / ${props.slides.length }`}</div>
          <IoIosArrowForward className='slider-button' onClick={toNextSlide}/>
        </div>}
        
    </StyledSlider>
  )
}

export default ImageSlider