
import { Suspense, useState } from 'react';
import { Welcome, Loading3d, About, Skills, Projects, Contacts, Footer, Navbar, Background2d, DimensionButton } from './components';

import { GlobalStyle } from './GlobalStyle.styles';
import { StyledSectionSpacer } from './components/styles/StyledSection';
import Canvas3d from './components/canvas3d/Canvas3d';
import { Canvas3dStyled } from './App.styles';

function App() {
  const [loaded,setLoaded] = useState(false)
  const [is3d, setIs3d] = useState(true)
  const isLoaded = (state) => {
    setLoaded(state)
  }


  return (
    <>
      <GlobalStyle />

      { is3d ? 
        <Canvas3dStyled > 
          <Suspense fallback={<Loading3d isLoaded={isLoaded} />}>
            <Canvas3d />
            
          </Suspense>
        </Canvas3dStyled>
      :
        <Background2d />
      }
      
      {(loaded || !is3d)  && (
        <>
          <DimensionButton toggle3d={setIs3d} is3d={is3d}/>
          <Navbar />
          <Welcome id="welcome" />
          {is3d && <StyledSectionSpacer />}
          <About id='about'/>
          {is3d && <StyledSectionSpacer />}
          <Skills id="skills"/>
          {is3d && <StyledSectionSpacer />}
          <Projects id="projects"/>
          {is3d && <StyledSectionSpacer />}
          <Contacts id="contacts" />
          <Footer />

        </>
      )}
      
    </>
    
    
  );
}


export default App;
