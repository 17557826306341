import { PerspectiveCamera, Stars, useTexture} from '@react-three/drei'
import {  useFrame } from '@react-three/fiber'
import {  useRef } from 'react';
import * as THREE from 'three'
import useScrollProgress from "../../hooks/useScrollProgress"


import { 
    CssLogo,
    JavaLogo,
    HtmlLogo,
    JsLogo,
    MySqlLogo,
    PythonLogo,
} from './models';


export default function Canvas3d() {
    const size = [6,6,6]
    const beginerPosition = [40,0,0]
    const beginerSelfRotation = 0.04
    const beginerObjRotation = 0.002

    const intermediatePosition = [20,0,0]
    const intermediateSelfRotation = 0.02
    const intermediateObjRotation = 0.015
    
    const [coreColor,coreDisplacement,coreMetal,coreNormal,coreRough] = useTexture(["textures/circuitCore.jpg","textures/circuitCoreDisplacement.jpg", "textures/circuitCoreMetal.jpg", "textures/circuitCoreNormal.jpg", "textures/circuitCoreRough.jpg" ])

    const cameraRef = useRef(null)
    const coreRef = useRef(null)
    const cssRef = useRef(null)
    const javaRef = useRef(null)
    const htmlRef = useRef(null)
    const jsRef = useRef(null)
    const sqlRef = useRef(null)
    const pythonRef = useRef(null)

    let scrollPosition = useScrollProgress()
    const camerPosition = new THREE.Vector3()
    const cameraAngle = new THREE.Quaternion()

    const angleToRadians = (angleInDeg) => (Math.PI / 180) * angleInDeg;
    function anglesToQuaternion (x,y,z){
        const cr = Math.cos(x * 0.5),
            sr = Math.sin(x * 0.5),
            cp = Math.cos(y * 0.5),
            sp = Math.sin(y * 0.5),
            cy = Math.cos(z * 0.5),
            sy = Math.sin(z * 0.5);

        
        const qx = sr * cp * cy - cr * sp * sy,
            qy = cr * sp * cy + sr * cp * sy,
            qz = cr * cp * sy - sr * sp * cy,
            qw = cr * cp * cy + sr * sp * sy;
            cameraAngle.fromArray([qx,qy,qz,qw])
        return cameraAngle
    }
    
    const zoomSpeed = 0.05
    const rotationSpeed = angleToRadians(0.5)
    const initialCamerZ = 400
    const initialCamerRotX = -45
    useFrame(() =>{
        
        coreRef.current.rotation.y += 0.004
        cameraRef.current.rotation.y += 0.0007
        
        if( scrollPosition < 38){
            cameraRef.current.children[0].children[0].position.lerp(camerPosition.set(0,0,initialCamerZ-(scrollPosition*9)),zoomSpeed)
            cameraRef.current.children[0].quaternion.slerp(anglesToQuaternion((initialCamerRotX + (scrollPosition*0.02)),0,0),rotationSpeed) //[ 0.5440211, 0, 0, -0.8390715 ]
        }
        else if(scrollPosition > 38){
            cameraRef.current.children[0].children[0].position.lerp(camerPosition.set(0,0,initialCamerZ-(38*9)),zoomSpeed)
            cameraRef.current.children[0].quaternion.slerp(anglesToQuaternion((initialCamerRotX + (38*0.02)),0,0),rotationSpeed)
        }
       
        
        cssRef.current.rotation.y += intermediateObjRotation
        cssRef.current.children[0].rotation.y += intermediateSelfRotation

        javaRef.current.rotation.y += intermediateObjRotation
        javaRef.current.children[0].rotation.y += intermediateSelfRotation

        htmlRef.current.rotation.y += intermediateObjRotation
        htmlRef.current.children[0].rotation.y += intermediateSelfRotation

        jsRef.current.rotation.y += intermediateObjRotation
        jsRef.current.children[0].rotation.y += intermediateSelfRotation

        sqlRef.current.rotation.y += beginerObjRotation
        sqlRef.current.children[0].rotation.y += beginerSelfRotation

        pythonRef.current.rotation.y += beginerObjRotation
        pythonRef.current.children[0].rotation.y += beginerSelfRotation
        

    })
    return(
        <>
        <ambientLight args={["#c2e9ff",1.2]} />
        <group position={[0,0,0]} ref={cameraRef}  >
            <group position={[0,0,0]} rotation-x={angleToRadians(-45)}>
                <PerspectiveCamera 
                    args={[45,
                    window.innerWidth / window.innerHeight,
                    0.1,
                    2000]} 
                    makeDefault
                    position={[0, 0, 400]}
                    />
            </group>
        </group>
        
        <Stars 
            radius={200}
            depth={100}
            count={5000}
            factor={4}
            saturation={0}
            
            
        />
        <mesh ref={coreRef} >
            <sphereGeometry 
                args={[8, 512, 512]}
            />
            <meshStandardMaterial 
                map={coreColor}
                normalMap={coreNormal}
                displacementMap={coreDisplacement}
                displacementScale={0.4}
                roughnessMap={coreRough}
                roughness={1}
                metalnessMap={coreMetal}
                metalness={0.3}
            />
        </mesh>
        <JavaLogo 
            position={intermediatePosition}
            scale={size}
            refs={javaRef}
            startingPosition={angleToRadians(0)} 
        />
        <CssLogo 
            position={intermediatePosition}
            scale={size}
            refs={cssRef}
            startingPosition={angleToRadians(90)}
        />

        <HtmlLogo 
            position={intermediatePosition}
            scale={size}
            refs={htmlRef}
            startingPosition={angleToRadians(180)}
        />
        <JsLogo 
            position={intermediatePosition}
            scale={size}
            refs={jsRef}
            startingPosition={angleToRadians(270)}
        />
        <MySqlLogo 
            position={beginerPosition}
            scale={size}
            refs={sqlRef}
            startingPosition={angleToRadians(0)}
        />

        <PythonLogo 
            position={beginerPosition}
            scale={size}
            refs={pythonRef}
            startingPosition={angleToRadians(180)}
        />
        </>
    )
}